.loader-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    min-height: 400px;
}
.loader {
    display: block;
    margin: 20px auto 0;
    width: 64px;
    height: 64px;

}
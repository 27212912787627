html, body, #root {
    min-height: 100vh;
    margin: 0;
    padding: 0;
}
#root {
    overflow: hidden;
}
#root * {
    font-family: 'Spartan', sans-serif;
}
.lang-ru  #root *,
.lang-el  #root *,
.lang-zh-tc  #root *,
.lang-zh-cn  #root * {
    font-family: 'Open Sans', sans-serif;
}
.overflow-hidden {
    overflow: hidden !important;
}
#root .page-content {
    background: #ecebeb;
    min-height: calc(100vh - 160px);
}
#root .v3 .page-content {
    padding-left: 15px;
    padding-right: 15px;
}
#root .page-content.bg-white {
    background: #ffffff;
}
#root .page-content.bg-grey {
    background: #f9f9f9;
}
#root>.loader-container {
    min-height: calc(100vh - 160px);
}
::-webkit-input-placeholder { /* Edge */
    color: #999997;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999997;
}
::placeholder {
    color: #999997;
}
#maskEmailField {
    visibility: 'hidden'; 
    font-size: 13px; 
    font-weight: 600; 
    position: absolute; 
    left: -9999999px;
}
.Toastify__toast-body a {
    color: #ffffff;
}
.lang-hr #root .MuiInputLabel-outlined.MuiInputLabel-shrink#expirationMonthYear-label {
    transform: translate(0px, -25px);
    line-height: 11px;
}
.MuiContainer-root .MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 10px 8px 12px;
}
.MuiFormLabel-root.MuiInputLabel-root.Mui-error {
    color: #283339;
}
.paymentSectionWrapper {
    padding-bottom: 100px;
}
.MuiFormControl-root .MuiOutlinedInput-root fieldset {
    border: none;
    top: -6px;
}
.MuiFormControl-root .MuiSelect-select:focus {
    background: transparent;
}
.MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 60px;
    left: 0;
    margin: 0;
    width: calc(100% + 15px);
    line-height: 1;
}
.MuiFormHelperText-root.Mui-error#email-helper-text {
    position: initial;
    padding-top: 5px;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
#root .blocked-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    background-size: cover;
    padding: 10px;
    color: #283339;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
}

.MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 15px !important;
}

/* .v2 .MuiInputLabel-outlined.MuiInputLabel-shrink .MuiInputLabel-asterisk {
    display: none;
} */
.v2 .MuiButton-contained.Mui-disabled {
    color: #ffffff;
    background-color: #d4d6d7;
}
.v2 .MuiFormControl-root .MuiOutlinedInput-root.Mui-disabled input:not([name=couponCode]) {
    opacity: 0.5;
    cursor: not-allowed;
}
.v2 .MuiContainer-maxWidthSm {
    padding-top: 20px;
    padding-bottom: 20px;
}
.v2 .paymentSectionWrapper {
    padding-bottom: 20px;
}

.v3 {
    min-height: 100vh;
    background: #E9EAEB;
}
.v3 .loader-container {
    min-height: calc(100vh - 143px);
}

.v3 .paymentSectionWrapper {
    padding-bottom: 10px;
}


.grecaptcha-badge {
    z-index: 100;
}
body > div:last-child[style*="visibility: visible;"] {
    position: fixed !important;
    top: 50px !important;
    bottom: 0 !important;
    z-index: 1000 !important;
}
@media (min-width: 1100px) {
    .MuiContainer-maxWidthMd {
        max-width: 1200px !important;
    }
}
@media (min-width: 680px) {
    .MuiContainer-maxWidthSm {
        max-width: 680px !important;
    }
    .v2 .MuiContainer-maxWidthSm {
        max-width: 750px !important;
    }
}